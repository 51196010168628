// Vendor
@import "../../node_modules/normalize-scss/sass/normalize";

// Core
@import "variables";
@import "typography";
@import "layout";

// Components
@import "components/loader";
@import "components/brand";
@import "components/textField";
@import "components/sign";
@import "components/dialog";
@import "components/navigation";
@import "components/menu";
@import "components/pageHeader";
@import "components/passwordChecker";
@import "components/onboarding";
@import "components/form";
@import "components/footer";
@import "components/tabs";
@import "components/devices";
@import "components/event";
@import "components/upload";
@import "components/subscription";
@import "components/plan";
@import "components/coupon";

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}