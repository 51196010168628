

.Brand {
  display: block;
  width: 112px;
  height: 14px;
  background: $ic-brand no-repeat center;
  background-size: 112px 14px;
  text-indent: -9999px;

  &--large {
    width: 134px;
    height: 17px;
    background-size: 134px 17px;
  }
}