
html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

* {
  font-family: $font-default !important;
}

body {
  font-weight: 400;
  quotes: "“" "”";
  color: $clr-text-primary;
  font-size: 16px;
}

body, input, textarea, select, button {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: 'liga', 'kern';
}

h1 {
  display: block;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 46px;
  letter-spacing: -1.5px;
}

h2 {
  margin-top: 40px;

  &:first-of-type {
    margin-top: 24px;
  }

  small {
    color: $clr-text-tercial;
  }
}

p {
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $clr-text-secondary;
  padding: 16px 0;
}

a {
  color: $clr-blue;
  text-decoration: none;
}

.Text {

  &--primary {
    color: $clr-blue !important;
  }

  &--running {
    color: $clr-green;
  }

  &--dns {
    color: $clr-orange;

    a {
      color: inherit;
      font-weight: bold;
    }
  }

  &--paused {
    color: $clr-red;

    a {
      color: inherit;
      font-weight: bold;
    }
  }

  &--faded {
    color: $clr-text-tercial;
  }
}

.DomainName {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: $clr-text-secondary;
  letter-spacing: 0;
  padding: 8px 16px;
  margin-top: 16px;
  border-radius: 4px;
  background: $clr-blue-extra-light;

  svg {
    position: relative;
    left: 4px;
    top: 3px;
  }
}