
.Dialog {

  &--help {

    h2 {
      margin-top: 0 !important;
    }

    h3 {
      padding-top: 16px;
    }

    p, li {
      font-size: 14px;
      padding: 8px 0;
    }

    ul {
      list-style-position: inside;
    }

    li {
      color: $clr-text-secondary;
      padding: 0;
    }
  }

  h2 {
    padding: 24px 24px 0;
    margin: 0;
  }

  h4 {
    color: $clr-text-primary;
    margin: 24px 0 4px;
  }

  .Chips {
    margin: -4px -4px 24px;

    & > div {
      margin: 4px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  p {
    padding: 0;
    color: $clr-text-secondary;

    strong {
      color: $clr-text-primary;
    }
  }

  &__cancel {
    float: right;

    @media(max-width: 480px) {
      float: none;
      display: block !important;
      margin-top: 12px !important;
      width: 100% !important;
    }
  }

  @media(max-width: 480px) {

    &__primary {
      width: 100% !important;
    }
  }

  &__spacer {
    display: block;
    margin-bottom: 24px;
  }

  &__box {
    width: 50%;
    float: left;
  }

  .clearfix {
    clear: both;
  }

  .Coupon {
    margin: 8px 0 8px;

    p {
      text-align: left;
    }
  }
}

.ExpansionPanel {
  margin: 16px 0;

  & > div {

  }
}