
.Navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  transition: box-shadow 100ms ease-in;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  z-index: 10;

  &--bordered {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .08);
  }

  &__helper {
    padding-bottom: 80px;
  }

  .Brand {
    margin: 31px 0;
    float: left;

    @media (max-width: 440px) {
      background: $ic-brand-square-blue no-repeat center;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
    }
  }

  &__back {
    display: block !important;
    position: absolute !important;
    top: 18px;
    left: -48px;
    color: $clr-text-primary !important;
  }

  &__title {
    float: left;
    position: relative;
    opacity: 0;
    animation: showTitle ease-in-out 200ms forwards;
    will-change: opacity;

    @media (max-width: 440px) {
      width: calc(100% - 48px);
    }

    @keyframes showTitle {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    & > span {
      display: block;
      margin: 22px 0 26px;
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 32px;
      letter-spacing: -0.5px;
      cursor: pointer;

      @media (max-width: 440px) {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__user {
    float: right;
    cursor: pointer;
    margin-right: -16px !important;

    @media (max-width: 440px) {
      padding: 16px 0 !important;
      margin-right: -8px !important;
    }

    &Item {
      border-radius: 36px !important;
      overflow: hidden !important;

      @media (max-width: 440px) {
        padding: 8px !important;
      }

      &Text {
        text-align: right;
        padding-left: 8px;
        margin-top: 0 !important;

        @media (max-width: 440px) {
          display: none !important;
        }

        * {
          font-family: $font-default !important;
        }

        & > span {
          font-weight: 600;
          color: $clr-text-primary;
          font-size: 14px;
        }

        & > p {
          line-height: 16px;
          font-size: 14px;
          color: $clr-text-tercial;
          padding: 0;
        }
      }
    }

    &Avatar {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background: $clr-blue-light;
      white-space: nowrap;
      text-overflow: clip;
      overflow: hidden;
      color: $clr-blue-dark;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      padding: 6px 0 7px 0;
      text-align: center;
      float: left;
      text-transform: uppercase;
      margin-left: 16px;
      user-select: none;

      @media (max-width: 440px) {
        margin: 0;
      }
    }

    &Name {
      display: block;
      float: left;
      text-align: right;
      line-height: 16px;
      font-size: 14px;
      color: $clr-text-tercial;
      padding-right: 16px;

      strong {
        display: block;
        font-weight: 600;
        color: $clr-text-primary;
        font-size: 14px;
      }
    }
  }
}

.Menu {
  padding-top: 0 !important;

  &__header {
    min-width: 224px;
    text-align: left;
    padding: 16px;
    margin-bottom: 8px;
    outline: none;
    border-bottom: 1px solid $clr-grey;

    .Navigation__userAvatar {
      display: block;
      margin: 0 auto 12px;
      float: none;
    }

    &Role {
      display: inline-block;
      color: $clr-blue;
      background: $clr-blue-extra-light;
      line-height: 16px;
      font-size: 11px;
      text-transform: uppercase;
      padding: 3px 8px 4px;
      font-weight: bold;
      border-radius: 12px;
      margin-bottom: 8px;
      user-select: none;
    }

    & > span {
      display: block;
      font-weight: 600;
      color: $clr-text-primary;
      font-size: 14px;
    }

    & > p {
      line-height: 16px;
      font-size: 14px;
      color: $clr-text-tercial;
      margin-bottom: 0;
      padding: 0;
    }
  }

  a {
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }

    * {
      font-family: $font-default !important;
    }
  }
}