
.Container {
  display: block;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
  margin: auto;

  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

html, body, div#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Content {
  flex: 1 0 auto;
}

.Animated {
  opacity: 0;
  animation: showAnim ease-in-out 300ms forwards;
  animation-delay: 200ms;
  will-change: opacity;

  @keyframes showAnim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.Loader {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}